import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./Common.css";
import "./mediaquery.css";
import MainProductEngineering from "./Components/Services/MainProductEngineering";
import MainCloudEnablement from "./Components/Services/MainCloudEnablement";
import MainDataEngineering from "./Components/Services/MainDataEngineering";
import RedirectUrls from "./RedirectUrls";
import MainAIMLSolutions from "./Components/Services/MainAIML";

const Home = lazy(() => import("./Components/Home/Home"));
const Services = lazy(() => import("./Components/Services/Services"));
const ServicesMain = lazy(() => import("./Components/Services/ServicesMain"));
const SucessStories = lazy(() =>
  import("./Components/Resources/SucessStories")
);
const BlogDetails = lazy(() => import("./Components/Blogs/BlogDetails"));
const SucessStoriesMain = lazy(() =>
  import("./Components/Resources/SucessStoriesMain")
);
const AboutUs = lazy(() => import("./Components/About/AboutUs"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const Industries = lazy(() => import("./Components/Industries/Industries"));
const IndustryDetail = lazy(() =>
  import("./Components/Industries/IndustryDetail")
);
const Blogs = lazy(() => import("./Components/Blogs/Blogs"));
const PrivacyStatement = lazy(() =>
  import("./Components/PrivacyStatement/PrivacyStatement")
);
const TermsAndConditions = lazy(() =>
  import("./Components/TermsAndCondition/TermsAndConditions")
);
const Innovation = lazy(() => import("./Components/Innovation/Innovation"));
const PageBuilder = lazy(() => import("./Components/Innovation/PageBuilder"));
const VAssist = lazy(() => import("./Components/Innovation/Vassist"));
const Whitepapers = lazy(() =>
  import("./Components/Whitepapers/Whitepaper")
);
const WhitepaperDetails = lazy(() =>
  import("./Components/Whitepapers/WhitepaperDetails")
);

function AppRoutes() {
  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <Spinner animation="border" variant="primary" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/" element={<Services />} />
        <Route path="/services/:id" element={<ServicesMain />} />
        <Route
          path="/services/product-engineering/:id/"
          element={<MainProductEngineering />}
        />
        <Route
          path="/services/data-engineering/:id/"
          element={<MainDataEngineering />}
        />
        <Route
          path="/services/ai-ml-solutions/:id/"
          element={<MainAIMLSolutions />}
        />
        <Route
          path="/services/cloud-enablement/:id/"
          element={<MainCloudEnablement />}
        />
        <Route path="/success-stories/" element={<SucessStories />} />
        <Route path="/aboutus/" element={<AboutUs />} />
        <Route path="/contactus/" element={<Contact />} />
        <Route path="/industries/" element={<Industries />} />
        <Route path="/industries/:id/" element={<IndustryDetail />} />
        <Route path="/blogs/" element={<Blogs />} />
        <Route path="/blogs/:id/" element={<BlogDetails />} />
        <Route path="/success-stories/:id/" element={<SucessStoriesMain />} />
        <Route path="/privacy-statement/" element={<PrivacyStatement />} />
        <Route path="/terms-and-conditions/" element={<TermsAndConditions />} />
        <Route path="/innovation/" element={<Innovation />} />
        <Route path="/innovation/page-builder/" element={<PageBuilder />} />
        <Route path="/innovation/v-assist/" element={<VAssist />} />
        <Route path="/whitepapers/" element={<Whitepapers />} />
        <Route path="/whitepapers/:id/" element={<WhitepaperDetails />} />
      </Routes>
      <RedirectUrls />
    </Suspense>
  );
}

export default AppRoutes;
